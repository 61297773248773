import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { DestinationModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class DestinationClient {
  constructor(private httpClient: HttpClient) {
    this.getDestinationById = this.getDestinationById.bind(this);
    this.getAllDestinationsForKendoGrid = this.getAllDestinationsForKendoGrid.bind(this);
    this.createDestination = this.createDestination.bind(this);
    this.updateDestination = this.updateDestination.bind(this);
    this.getAllDestinations = this.getAllDestinations.bind(this);
    this.listByIntegration = this.listByIntegration.bind(this);
  }

  public async getAllDestinations() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const destinations = await this.httpClient.fetchAndParse<DestinationModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations`, options),
    );
    return destinations;
  }

  public async listByIntegration(integration: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const destinations = await this.httpClient.fetchAndParse<DestinationModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations/ListByIntegration/${encodeURIComponent(integration)}`, options),
    );
    return destinations;
  }

  public async getDestinationById(destinationId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const destination = await this.httpClient.fetchAndParse<DestinationModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations/${encodeURIComponent(destinationId)}`, options),
    );
    return destination;
  }

  public async getAllDestinationsForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<DestinationModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations/grid?${queryStr}`, options),
    );
    return result;
  }

  public async createDestination(destination: DestinationModel) {
    const options = await this.httpClient.createStandardOptions('POST', destination);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations`, options));
    return result;
  }

  public async updateDestination(destination: DestinationModel) {
    const options = await this.httpClient.createStandardOptions('PUT', destination);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Destinations`, options));
    return result;
  }
}
