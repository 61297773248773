import { useContext, useMemo } from 'react';

import { SettingModel } from 'models';

import { equalsInsensitive, hasText } from 'core/utils';

import { BASE_HOME_ITEM } from 'features/main/constants';

import { UserSettingsContext } from '../contexts';
import { SettingsHelpers } from '../services';
import { UserSettingsContextType, WellKnownSettingName } from '../types';

export function useUserSettings(required: true): {
  userSettings: SettingModel[];
  refreshSettings: UserSettingsContextType['refreshSettings'];
  homePageUrl: string;
  reactPatientFormPage: boolean;
  reactPatientGridPage: boolean;
  reactSharePage: boolean;
  reactReadRequestPage: boolean;
  reactUploadPage: boolean;
  donorPageUrl: string;
  reactExams: boolean;
  reactBaseUrl: string;
  legacyBaseUrl: string;
  fullScreenReading: boolean;
  queryRetrieve: boolean;
};
export function useUserSettings(required?: false): {
  userSettings: SettingModel[] | null;
  refreshSettings: UserSettingsContextType['refreshSettings'];
  homePageUrl: string | null;
  reactPatientFormPage: boolean | null;
  reactPatientGridPage: boolean | null;
  reactSharePage: boolean | null;
  reactReadRequestPage: boolean | null;
  reactUploadPage: boolean | null;
  donorPageUrl: string | null;
  reactExams: boolean | null;
  reactBaseUrl: string | null;
  legacyBaseUrl: string | null;
  fullScreenReading: boolean | null;
  queryRetrieve: boolean | null;
};
export function useUserSettings(required = false): {
  userSettings: SettingModel[] | null;
  refreshSettings: UserSettingsContextType['refreshSettings'];
  homePageUrl: string | null;
  reactPatientFormPage: boolean | null;
  reactPatientGridPage: boolean | null;
  reactSharePage: boolean | null;
  reactReadRequestPage: boolean | null;
  reactUploadPage: boolean | null;
  donorPageUrl: string | null;
  reactExams: boolean | null;
  reactBaseUrl: string | null;
  legacyBaseUrl: string | null;
  fullScreenReading: boolean | null;
  queryRetrieve: boolean | null;
} {
  const { isInitialized, userSettings, refreshSettings } = useContext(UserSettingsContext);

  const homePageUrl = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const homePageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.HomePageUrl, false);

    if (homePageSetting && hasText(homePageSetting.value)) {
      const absoluteUrl: string =
        homePageSetting.value.startsWith('http://') || homePageSetting.value.startsWith('https://')
          ? homePageSetting.value
          : new URL(homePageSetting.value, window.location.origin).toString();

      return absoluteUrl;
    } else {
      return BASE_HOME_ITEM.defaultExternalRoute ?? null;
    }
  }, [userSettings]);

  const reactPatientFormPage = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactPatientFormPageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactPatientFormPage, false);

    return equalsInsensitive('true', reactPatientFormPageSetting?.value);
  }, [userSettings]);

  const reactPatientGridPage = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactPatientGridPageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactPatientGridPage, false);

    return equalsInsensitive('true', reactPatientGridPageSetting?.value);
  }, [userSettings]);

  const reactSharePage = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactSharePageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactSharePage, false);

    return equalsInsensitive('true', reactSharePageSetting?.value);
  }, [userSettings]);

  const reactReadRequestPage = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactReadRequestPageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactReadRequestPage, false);

    return equalsInsensitive('true', reactReadRequestPageSetting?.value);
  }, [userSettings]);

  const reactUploadPage = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactUploadPageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactUploadPage, false);

    return equalsInsensitive('true', reactUploadPageSetting?.value);
  }, [userSettings]);

  const donorPageUrl = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const donorPageUrlSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.DonorPageUrl, false);

    if (donorPageUrlSetting && hasText(donorPageUrlSetting.value)) {
      return donorPageUrlSetting.value;
    }
    return null;
  }, [userSettings]);

  const reactExams = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactUploadPageSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ReactExams, false);

    return equalsInsensitive('true', reactUploadPageSetting?.value);
  }, [userSettings]);

  const legacyBaseUrl = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const legacyBaseUrlSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.LegacyBaseUrl, false);

    if (
      legacyBaseUrlSetting &&
      hasText(legacyBaseUrlSetting.value) &&
      !legacyBaseUrlSetting.value.startsWith('http://') &&
      !legacyBaseUrlSetting.value.startsWith('https://')
    ) {
      throw new Error('The LegacyBaseUrl setting must start with "http://" or "https://".');
    }

    const parsedUrl = legacyBaseUrlSetting && hasText(legacyBaseUrlSetting.value) ? new URL(legacyBaseUrlSetting.value) : new URL(window.location.origin);

    // If the port isn't specified then it by definition will be the default port for the protocol: https://developer.mozilla.org/en-US/docs/Web/API/URL/port
    return hasText(parsedUrl.port) ? `${parsedUrl.protocol}//${parsedUrl.hostname}:${parsedUrl.port}` : `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  }, [userSettings]);

  const reactBaseUrl = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const reactBaseUrlSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.LegacyBaseUrl, false);

    if (
      reactBaseUrlSetting &&
      hasText(reactBaseUrlSetting.value) &&
      !reactBaseUrlSetting.value.startsWith('http://') &&
      !reactBaseUrlSetting.value.startsWith('https://')
    ) {
      throw new Error('The LegacyBaseUrl setting must start with "http://" or "https://".');
    }

    const parsedUrl = reactBaseUrlSetting && hasText(reactBaseUrlSetting.value) ? new URL(reactBaseUrlSetting.value) : new URL(window.location.origin);

    // If the port isn't specified then it by definition will be the default port for the protocol: https://developer.mozilla.org/en-US/docs/Web/API/URL/port
    return hasText(parsedUrl.port) ? `${parsedUrl.protocol}//${parsedUrl.hostname}:${parsedUrl.port}` : `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  }, [userSettings]);

  const fullScreenReading = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const fullScreenReadingSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.FullScreenReading, false);

    return equalsInsensitive('true', fullScreenReadingSetting?.value);
  }, [userSettings]);

  const queryRetrieve = useMemo(() => {
    if (userSettings == null) {
      return null;
    }

    const queryRetrieveSetting = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.QueryRetrieve, false);

    return equalsInsensitive('true', queryRetrieveSetting?.value);
  }, [userSettings]);

  if (!isInitialized && required) {
    throw new Error('Failed useUserSettings because the userSettings property is null.');
  }

  return {
    userSettings,
    refreshSettings,
    homePageUrl,
    reactPatientFormPage,
    reactPatientGridPage,
    reactSharePage,
    reactReadRequestPage,
    reactUploadPage,
    donorPageUrl,
    reactExams,
    legacyBaseUrl,
    reactBaseUrl,
    fullScreenReading,
    queryRetrieve,
  };
}
