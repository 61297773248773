export enum WellKnownSettingName {
  HomePageUrl = 'HomePageUrl',
  DonorPageUrl = 'DonorPageUrl',
  ReactPatientFormPage = 'ReactPatientFormPage',
  ReactPatientGridPage = 'ReactPatientGridPage',
  Tags = 'Tags',
  EnableReadRejection = 'EnableReadRejection',
  ReactSharePage = 'ReactSharePage',
  ReactReadRequestPage = 'ReactReadRequestPage',
  ReactUploadPage = 'ReactUploadPage',
  ShowGenerateReport = 'ShowGenerateReport',
  LegacyBaseUrl = 'LegacyBaseUrl',
  ReactExams = 'ReactExams',
  ReactBaseUrl = 'ReactBaseUrl',
  FullScreenReading = 'FullScreenReading',
  QueryRetrieve = 'QueryRetrieve',
}
