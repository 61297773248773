import { FunctionComponent, useMemo } from 'react';

import { cloneDeep, map } from 'lodash';
import { Outlet } from 'react-router-dom';

import { equalsInsensitive, hasText } from 'core/utils';

import { UserRoles, useAuthentication, useCurrentUser } from 'features/auth';
import { SettingsHelpers, WellKnownSettingName, useUserSettings } from 'features/settings';

import { BASE_HOME_ITEM, HideNavbarPaths, NAVIGATION_LINK_IDS, NavigationItems } from '../constants';
import { NavigationSidebar } from './NavigationSidebar';

export const App: FunctionComponent = () => {
  const { activeScheme } = useAuthentication();
  const { userSettings, reactPatientGridPage, reactExams, reactUploadPage, queryRetrieve } = useUserSettings(false);
  const { currentUser } = useCurrentUser(false);

  const navigationItems = useMemo(() => {
    // Return nothing until the user and settings have been fetched.
    if (userSettings == null || currentUser == null) return [];

    const newItems = cloneDeep(map(NavigationItems));

    // Add "Home" link based on settings.

    const homeItemIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.HOME);

    if (homeItemIndex < 0) {
      throw new Error(`Could not find navigation item for "Home" expected id ${NAVIGATION_LINK_IDS.HOME}.`);
    }

    const homePageUrl = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.HomePageUrl, false);

    const newHomeItem = {
      text: BASE_HOME_ITEM.text,
      icon: BASE_HOME_ITEM.icon,
      id: NAVIGATION_LINK_IDS.HOME,
      externalRoute: BASE_HOME_ITEM.defaultExternalRoute,
    };

    if (homePageUrl && hasText(homePageUrl.value)) {
      const absoluteUrl: string =
        homePageUrl.value.startsWith('http://') || homePageUrl.value.startsWith('https://')
          ? homePageUrl.value
          : new URL(homePageUrl.value, window.location.origin).toString();

      newHomeItem.externalRoute = absoluteUrl;
    }

    // Figure out link to use for "Patients" item based on settings.
    const patientItemIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.PATIENTS);
    const patientItem = newItems[patientItemIndex];

    if (reactPatientGridPage && 'externalRoute' in patientItem) {
      delete patientItem.externalRoute;
    } else if (!reactPatientGridPage && 'route' in patientItem) {
      delete (patientItem as unknown as Partial<typeof NavigationItems.PATIENT>).route;
    }

    // Figure out link to use for the "Exams" item based on settings.
    const examsItemIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.EXAMS);
    const examsItem = newItems[examsItemIndex];

    if (reactExams && 'externalRoute' in examsItem) {
      delete examsItem.externalRoute;
    } else if (!reactExams && 'route' in examsItem) {
      delete (examsItem as unknown as Partial<typeof NavigationItems.EXAMS>).route;
    }

    // Figure out link to use for the "Upload Exam" item based on settings.
    const uploadExamsIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.UPLOAD_EXAM);
    const uploadExamsItem = newItems[uploadExamsIndex];

    if (reactUploadPage && 'externalRoute' in uploadExamsItem) {
      delete uploadExamsItem.externalRoute;
    } else if (!reactUploadPage && 'route' in uploadExamsItem) {
      delete (uploadExamsItem as unknown as Partial<typeof NavigationItems.UPLOAD_EXAM>).route;
    }

    // Remove "Exams", "Upload Exam", and "Bulk Upload" for physicians.
    if (currentUser === 'anonymous' || equalsInsensitive(currentUser.role, UserRoles.PHYSICIAN)) {
      const removeItemIds = [NAVIGATION_LINK_IDS.EXAMS, NAVIGATION_LINK_IDS.UPLOAD_EXAM, NAVIGATION_LINK_IDS.BULK_UPLOAD];

      for (const removeId of removeItemIds) {
        const itemIndex = newItems.findIndex((i) => 'id' in i && i.id === removeId);

        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        }
      }
    }

    // Remove "Bulk Upload" for customers.
    if (currentUser === 'anonymous' || equalsInsensitive(currentUser.role, UserRoles.CUSTOMER)) {
      const removeItemIds = [NAVIGATION_LINK_IDS.BULK_UPLOAD];

      for (const removeId of removeItemIds) {
        const itemIndex = newItems.findIndex((i) => 'id' in i && i.id === removeId);

        if (itemIndex >= 0) {
          newItems.splice(itemIndex, 1);
        }
      }
    }

    // Remove "Portal Settings" for everyone but system administrators.
    if (currentUser === 'anonymous' || !currentUser.isSystemAdmin) {
      const itemIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.PORTAL_SETTINGS);

      if (itemIndex >= 0) {
        newItems.splice(itemIndex, 1);
      }
    }

    //remove query retrieve if the user doens't have the setting
    if (currentUser === 'anonymous' || !queryRetrieve) {
      const itemIndex = newItems.findIndex((i) => 'id' in i && i.id === NAVIGATION_LINK_IDS.QUERY_RETRIEVE);

      if (itemIndex >= 0) {
        newItems.splice(itemIndex, 1);
      }
    }

    return newItems;
  }, [currentUser, reactExams, reactPatientGridPage, reactUploadPage, userSettings]);

  return (
    <NavigationSidebar navigationItems={navigationItems} hideNavbarPaths={HideNavbarPaths} scheme={activeScheme}>
      <Outlet />
    </NavigationSidebar>
  );
};

App.displayName = 'App';
